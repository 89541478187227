<template>
    <div style="height:100%" class="d-flex">
        <nav-drawer />
        <div class="pa-3" style="width:100%; margin-left:50px">
            <div class="caption">{{$route.name}}</div>
            <v-divider class="my-3"/>
            <router-view >
                
            </router-view>
        </div>
    </div>
</template>

<script>
import NavDrawer from '../components/DashboardComponents/NavDrawer.vue'

export default {
    components: { NavDrawer },
    data:()=>({
       
    })
}
</script>

<style scoped>

</style>