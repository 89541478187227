<template>
    <v-navigation-drawer 
        v-if="items"
        permanent
        expand-on-hover
        fixed
    >
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="title pa-3">
                    管理画面
                </v-list-item-title>
                <v-list-item-subtitle class="subtitle mb-3"> 
                    <v-chip color=primary>
                        {{this.$store.state.userProfile.name}}
                    </v-chip>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle"> {{this.$store.state.version}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
    
        <v-list dense nav>
            <v-list-item 
                v-for="item in items"
                :key="item.title" 
                link 
                @click="$router.replace(item.link)"
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!--- Logout -->
            <v-list-item 
                @click="$store.dispatch('logout')"
            >
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>ログアウト</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- Pin only mode -->
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-btn 
                            @click="pinOnly"
                            class="py-6 black--text font-weight-bold"
                            color=warning>
                            社員コード専用<br/>
                            ログイン画面
                        </v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- Dark mode switch -->
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                        <v-switch
                            prepend-icon="mdi-theme-light-dark"
                            color="primary"
                            @change="switchTheme()"
                        ></v-switch>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    data: () => ({
        items: null,
        right: null,
    }),
    mounted(){
        this.generateMenuItems()
        this.loadMode();
    },
    methods:{
        async pinOnly() {
            let response = await this.$store.dispatch('post', {
                url:"/auth/activatePinOnlyMode", showUI:true,
                rawData:{
                    uid:this.$store.state.userProfile.id
                }
            })
            if(response && response.result)
                if(response.result.privateLogin){
                    this.$store.commit("activatePrivateLogin");
                    this.$store.dispatch('logout')
                }
                    
        },
        async generateMenuItems(){
            let defaultMenu = [
                { title: "ホーム", routeName:"ホーム", icon: "mdi-view-dashboard", link:"/dash/" },
                { title: "ユーザー管理", routeName:"ユーザー管理" ,icon: "mdi-account-multiple", link:"/dash/userlist" },
                { title: "権限管理", routeName:"権限管理" ,icon: "mdi-account-key", link:"/dash/userRoles" },
                { title: "シフト管理", routeName:"シフト管理" ,icon: "mdi-calendar-account", link:"/dash/shift" },
                { title: "班管理", routeName:"班管理" ,icon: "mdi-account-box-multiple", link:"/dash/team" },
                { title: "現場・工種管理", routeName:"現場・工種管理" ,icon: "mdi-factory", link:"/dash/GenbaKoushu" },
                { title: "QRコード作成", routeName:"QRコード作成" ,icon: "mdi-qrcode-plus", link:"/dash/cqrcode" },
                { title: "単価設定", routeName:"単価設定" ,icon: "mdi-account-cash", link:"/dash/rateSettings" },
                { title: "有給管理", routeName:"有給管理" ,icon: "mdi-calendar-star", link:"/dash/paidLeave" },
                { title: "休日・祝日設定", routeName:"休日・祝日設定" ,icon: "mdi-calendar-clock", link:"/dash/calendar" },
                { title: "その他の設定", routeName:"その他の設定" ,icon: "mdi-cog-outline", link:"/dash/otherSettings" },
                { title: "申請一覧", routeName:"申請一覧" ,icon: "mdi-form-select", link:"/dash/shinsei" },
                { title: "統計", routeName:"統計" ,icon: "mdi-chart-areaspline", link:"/dash/toukei" },
            ]
            let reconstructedMenu = []
            for(let item of defaultMenu){
                let result = await this.$store.dispatch('checkAccessibility', item.routeName)
                if(result === true) reconstructedMenu.push(item)
            }
            this.items = reconstructedMenu
        },
        loadMode(){
            if(Boolean(localStorage.getItem('d'))){
                this.$vuetify.theme.dark = Boolean(localStorage.getItem('d'))
                this.darkMode = true
            }
                
        },
        switchTheme(){
            if (!this.$vuetify.theme.dark) localStorage.setItem("d", true);
            else localStorage.removeItem("d");
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
    }
};
</script>

<style scoped>
.title, .subtitle{
    text-align: center;
}

</style>
